export const posRouter = [
    {
        path: '/pos/:id_business',
        component: () => import('../views/pos/posIndex.vue'),
        children: [
            { 
                path: 'inicio',
                component: () => import('../views/pos/controlPanel/controlPanel.vue') 
            },
            {
                path: 'registradora-pc', 
                component: () => import('../views/pos/register/posRegisterPC.vue'),
            },
            {
                path: 'consultar-productos', 
                component: () => import('../views/pos/search/posSearchProducts.vue'),
            },
            {
                path: 'registros', 
                component: () => import('../views/pos/records/posRecordsList.vue'),
            },
        ]
    },
]
