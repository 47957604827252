<template>

  <router-view></router-view>

</template>

<script>

import { onMounted, ref } from 'vue'

export default {
  name: 'App',
  components: {
  },
  setup() {

    onMounted(async () => {

    })

    return {



    }
  }
}
</script>
