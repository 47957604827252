export const inventoryRouter = [
    {
        path: '/inventario/:id_business',
        component: () => import('../views/inventory/inventoryIndex.vue'),
        children: [
            { 
                path: 'panel-de-control',
                component: () => import('../views/inventory/controlPanel/controlPanel.vue') 
            },
            {
                path: 'productos', 
                component: () => import('../views/inventory/products/inventoryProductsList.vue'),
            },
            { 
                path: 'producto/detalle', 
                component: () => import('../views/inventory/products/inventoryProductsIndex.vue'),
                children:[
                    
                    { 
                        path: 'resumen/:id_product', 
                        component: () => import('../views/inventory/products/inventoryProductSummary.vue')
                    },
                    { 
                        path: 'general/:id_product', 
                        component: () => import('../views/inventory/products/inventoryProductCreate.vue')
                    },
                    { 
                        path: 'ingresos/:id_product', 
                        component: () => import('../views/inventory/productsIncommings/inventoryProductIncomes.vue')
                    },
                    { 
                        path: 'salidas/:id_product', 
                        component: () => import('../views/inventory/productsDepartures/inventoryProductDepartures.vue')
                    },
                    { 
                        path: 'alertas/:id_product', 
                        component: () => import('../views/inventory/productsAlerts/inventoryProductAlerts.vue')
                    },
                    { 
                        path: 'atributos/:id_product', 
                        component: () => import('../views/inventory/productsAlerts/inventoryProductAlerts.vue')
                    },
                    { 
                        path: 'movimientos/:id_product', 
                        component: () => import('../views/inventory/productsMovements/inventoryProductMovements.vue')
                    },
                ]
            },
            {
                path: 'producto/detalle/ingresos/:id_product/manager/:id_incoming', 
                component: () => import('../views/inventory/productsIncommings/inventoryProductIncomingManager.vue'),
            },
            {
                path: 'proveedores', 
                component: () => import('../views/inventory/providers/inventoryProvidersList.vue'),
            },
            {
                path: 'proveedores/gestionar/:id_provider', component: () => import('../views/inventory/providers/inventoryProviderManager.vue'),
            },
            {
                path: 'estadisticas', 
                component: () => import('../views/inventory/statistics/incentaryStatistics.vue'),
            },
            {
                path: 'bodegas', 
                component: () => import('../views/inventory/cellars/inventoryCellarsList.vue'),
            },
            {
                path: 'bodegas/gestionar/:id_cellar', 
                component: () => import('../views/inventory/cellars/inventoryCellarManager.vue'),
            },
            {
                path: 'grupos', 
                component: () => import('../views/inventory/groups/inventoryGroupsList.vue'),
            },
            {
                path: 'grupos/gestionar/:id_group', component: () => import('../views/inventory/groups/inventoryGroupManager.vue'),
            },
       
       
       
            
            
            
        ]
    },
]


// inventoryProviderManagerIndex.vue