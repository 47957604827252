export const freeSalesRouter = [
    {
        path: '/ventas-libres/:id_business',
        component: () => import('../views/sales/salesIndex.vue'),
        children: [
            { 
                path: '',
                component: () => import('../views/pos/controlPanel/controlPanel.vue') 
            },
            {
                path: 'registradora-pc', 
                component: () => import('../views/pos/register/posRegisterPC.vue'),
            },
            {
                path: 'registros', 
                component: () => import('../views/pos/records/posRecordsList.vue'),
            },
        ]
    },
]
