import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router'

import { businessRouter } from './businessRouter'
import { inventoryRouter } from './inventoryRouter'
import { posRouter } from './posRouter'
import { salesRouter } from './salesRouter'
import { freeSalesRouter } from './freeSalesRouter'
import { usersRouter } from './usersRouter'




const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    component: () => import('./../views/Navigation.vue')
  },
  {
    path: '/inicio',
    component: () => import('./../views/Navigation.vue')
  },
  {
    path: '/crear-cuenta',
    component: () => import('./../views/login/Signup.vue')
  },
]

const collectionRouters = [
  ...routes,
  ...businessRouter,
  ...inventoryRouter,
  ...posRouter,
  ...salesRouter,
  ...freeSalesRouter,
  ...usersRouter,
];

const router = createRouter({
  history: createWebHashHistory(process.env.VUE_APP_BASE_URL),
  routes: collectionRouters
})

export default router
