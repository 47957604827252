export const usersRouter = [
    {
        path: '/usuarios/:id_business',
        component: () => import('../views/users/usersIndex.vue'),
        children: [
            { 
                path: '',
                component: () => import('../views/pos/controlPanel/controlPanel.vue') 
            },
            {
                path: 'registradora-pc', 
                component: () => import('../views/pos/register/posRegisterPC.vue'),
            },
            {
                path: 'registros', 
                component: () => import('../views/pos/records/posRecordsList.vue'),
            },
        ]
    },
]
